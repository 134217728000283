@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Luckiest+Guy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Darumadrop+One&display=swap");

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
/* 
* {
  outline: 1px solid red;
} */

body {
  margin: 0;
  padding: 0;
  width: 100vw; /* 100% of viewport width */
  min-height: 100vh; /* Minimum height is 100% of viewport height */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.card {
  box-shadow: 0px 4px 4px 0px #00000040;
}
.text-outline {
  color: white;
  text-shadow: 1px 1px 0 #122243, -1px -1px 0 #122243, 1px -1px 0 #122243,
    -1px 1px 0 #122243;
}

.text-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0b1428;
}

.text-stroke-light {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #0b1428;
}
.text-stroke-thick {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #0b1428;
}
.text-stroke-dense {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #0b1428;
}

.golden-text-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0b1428;
}

.text-thick-stroke {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0b1428;
}

.text-stroke-reverse {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #00bde5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: Arial, sans-serif;
}
/* 
nav {
  display: flex;
  gap: 10px;
}

nav a {
  text-decoration: none;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
} */

.stroke-primary {
  color: #000000; /* White color for the text */
  -webkit-text-stroke: 2px #ffffff; /* Black outline */
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
    1px 1px 0 #000000;
}
